import React, { useState, useEffect } from "react"
import Helmet from "react-helmet"
import { Router } from '@reach/router'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"


import Layout from '../../components/layout'
import Header from '../../components/Header'


import pic01 from '../../assets/images/pic01.jpg'
import pic02 from '../../assets/images/pic02.jpg'
import pic03 from '../../assets/images/pic03.jpg'
import pic04 from '../../assets/images/pic04.jpg'

import { queryAudioData } from "../../lib/API"
import { useData } from "../../lib/hooks"
import { VideoPreview } from "../../components/Video"
import VideoPage from '../../templates/video'
import moment from 'moment'

import Demo from '../../assets/audio/demo.mp3'
import { AudioPreview } from "../../components/Audio"

const MediaPage = () => {
    const today = new Date().toISOString().split( "T" )[0].split( "-" ).slice( 0,2 ).join( "-" )
    const [ queryParams, setQueryParams ] = useState( { _sort: "Date:DESC" } )
    const [ minDate, setMinDate ] = useState( new Date( new Date().setFullYear( new Date().getFullYear() - 1 ) ) )
    const [ maxDate, setMaxDate ] = useState( new Date() )
    const audios = useData( queryAudioData, queryParams )
    const siteTitle = "Blackheath and Charlton Baptist Church - Audio Sermons"
    const ssrNavigator = typeof window !== "undefined" ? navigator : null



    useEffect( () => {
        const maxDateObj = new Date( maxDate )
        const minDateObj = new Date( minDate )
        const maxDateFilter = new Date( maxDateObj.setMonth( maxDateObj.getMonth()+1 ) )

        const params = minDate ? {
            _sort: "Date:DESC",
            date_lte: maxDateFilter.toISOString(),
            date_gte: minDateObj.toISOString(),
        } 
        :
        {
            _sort: "Date:DESC",
            date_lte: maxDateFilter ? maxDateFilter.toISOString() : new Date( today ).toISOString(),
            
        }
        console.log( params )
        setQueryParams( params )

    }, [ minDate, maxDate ] )

    return (
            
        <>
            <Helmet title={siteTitle} />
            <Router basepath="/media/audio">
                <VideoPage path="/:id" />
            </Router>
            
            <section id="audio-sermons" className="main style1 special">
                <header className="major">
                    <h2>All Audio Sermons</h2>
                </header>
                {/* {( !ssrNavigator || ( ssrNavigator && ssrNavigator.userAgent.indexOf( "Firefox" ) === -1 ) ) && (
                <div className="filters">
                    <span> from &emsp;</span>
                    <input style={{ display:  "inline" }} type="month" name="date-from" max={maxDate} value={null} onChange={( e ) => setMinDate( e.target.value )} />
                    <span>&emsp; to &emsp;</span>
                    <input style={{ display:  "inline" }} type="month" name="date-to" max={today} onChange={( e ) => setMaxDate( e.target.value )} />
                </div> )}
                {( ssrNavigator && ssrNavigator.userAgent.indexOf( "Firefox" ) !== -1 ) && (
                <div className="filters">
                    <span> from &emsp;</span>
                    <DatePicker 
                        onChange={date => setMinDate( date )} 
                        selected={minDate || new Date( new Date().setFullYear( new Date().getFullYear() - 1 ) )}
                        dateFormat="MMM yyyy"
                        maxDate={maxDate}
                        showMonthYearPicker
                        showFullMonthYearPicker
                        showTwoColumnMonthYearPicker 
                    />
                    <span>&emsp; to &emsp;</span>
                    <DatePicker 
                        onChange={date => setMaxDate( date )} 
                        dateFormat="MMM yyyy"
                        selected={maxDate}
                        maxDate={new Date()}
                        showMonthYearPicker
                        showFullMonthYearPicker
                        showTwoColumnMonthYearPicker 
                    />
                </div> )} */}
                <br />
                <div className="video-grid">
                    { audios && audios.map( audio => <AudioPreview {...audio} /> )}
                    {/* <AudioPreview title="sermon" description="The time where we did something and it turned out to be ok" url={Demo} date={new Date()}  />
                    <AudioPreview title="sermon" description="The time where we did something and it turned out to be ok" url={Demo} date={new Date()}  />

                    <AudioPreview title="sermon" description="The time where we did something and it turned out to be ok" url={Demo} date={new Date()}  /> */}

                </div>
            </section>
        </>
    )
}

export default MediaPage